export default () => {
    Alpine.directive("custom-scrollbar", (el, { value }, { effect }) => {
        const thumbEl = el.querySelector(".scrollbar-thumb");
        const contentEl = el.querySelector(".container-content");
        const trackEl = el.querySelector(".scrollbar-track");
        const scrollbarEl = el.querySelector(".scrollbar");

        if (!thumbEl || !contentEl || !trackEl || !scrollbarEl) return

        const handleEvents = () => {
            if (contentEl.scrollHeight <= contentEl.clientHeight) {
                scrollbarEl.classList.add('hidden');
                return
            } else {
                scrollbarEl.classList.remove('hidden');
            }

            let startPos = null;
            const scrollRatio = contentEl.clientHeight / contentEl.scrollHeight;
            thumbEl.style.height = `${scrollRatio * 100}%`;

            thumbEl.addEventListener('mousedown', (e) => {
                startPos = {
                    top: contentEl.scrollTop,
                    x: e.clientX,
                    y: e.clientY,
                };
                thumbEl.style.cursor = "grabbing";
                thumbEl.style.userSelect = "none";
                document.body.style.cursor = "grabbing";
                document.body.style.userSelect = "none";;
            });

            document.addEventListener('mousemove', (e) => {
                if (!startPos) return;
                const dy = e.clientY - startPos.y;
                const scrollRatio = (contentEl.scrollHeight - contentEl.clientHeight) / (trackEl.clientHeight - thumbEl.clientHeight);
                contentEl.scrollTop = startPos.top + dy * scrollRatio;
            });

            document.addEventListener('mouseup', () => {
                startPos = null;
                thumbEl.style.cursor = "grab";
                thumbEl.style.userSelect = "";
                document.body.style.cursor = "";
                document.body.style.userSelect = "";
            });

            trackEl.addEventListener('click', (e) => {
                const bound = trackEl.getBoundingClientRect();
                const percentage = (e.clientY - bound.top) / bound.height;
                contentEl.scrollTop = percentage * (contentEl.scrollHeight - contentEl.clientHeight);
            });

            contentEl.addEventListener('scroll', () => {
                const scrollRatio = contentEl.scrollTop / contentEl.scrollHeight;
                thumbEl.style.top = `${scrollRatio * 100}%`;
            });
        };


        const debounce = (func, delay) => {
            let debounceTimer;
            return function () {
                const context = this;
                const args = arguments;
                clearTimeout(debounceTimer);
                debounceTimer = setTimeout(() => func.apply(context, args), delay);
            };
        };


        const observer = new MutationObserver(() => {
            if (!document.body.contains(contentEl)) {
                observer.disconnect();
                return;
            }

            handleEvents()
        });
        observer.observe(contentEl, { attributes: true, childList: true, subtree: true });


        window.addEventListener('load', handleEvents);
        window.addEventListener('resize', debounce(handleEvents, 250));
    });
};
