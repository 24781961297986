export default () => {
    Alpine.directive("intro-animation", (el, { expression }, { effect }) => {
        const inner = document.getElementById("inner");
        let hasPlayed
        try {
            hasPlayed = sessionStorage.getItem("hasPlayed");
        } catch (e) {
            hasPlayed = true
        }

        const removeIntro = () => {
            el.style.display = "none";
            inner.classList.remove("!hidden")
        }

        if (hasPlayed) {
            removeIntro()
            document.addEventListener("htmx:afterSettle", () => {
                removeIntro()
            })
            return
        }


        const body = document.querySelector("body");
        body.classList.add("overflow-hidden");
        inner.classList.add("translate-y-[100vh]");
        setTimeout(() => inner.classList.remove("!hidden"), 500);

        setTimeout(() => {
            inner.style.transition = "transform 750ms cubic-bezier(1, 0.1, 0.1, 1)";
            body.classList.remove("overflow-hidden");
            inner.classList.remove("translate-y-[100vh]");
            inner.addEventListener("transitionend", () => {
                el.style.display = "none";
            });
            sessionStorage.setItem("hasPlayed", true);
        }, 2200)
    });


};
