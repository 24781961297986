export default () => {
    Alpine.store('menu', {
        init() {
            this.open = this.isDesktop
            window.addEventListener('resize', () => {
                const isDesktop = window.innerWidth > 1023
                this.isDesktop = isDesktop
                isDesktop ? this.open = true : this.open = false

            })
        },

        open: false,
        isDesktop: window.innerWidth > 1023,

        toggle() {
            this.open = !this.open
        },

        isMobileAndOpen() {
            return !this.isDesktop && this.open
        }
    })
}
